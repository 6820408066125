import { Application } from '@hotwired/stimulus'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

// Turbo.setFormMode( "off")

export { application }

import 'flowbite'
window.document.addEventListener('turbo:render', _event => {
	window.initFlowbite()
})
